.alert {
  border: solid 1px white;
  border-radius: 5px;
  padding: 10px;
}

.alert-danger {
  background-color: #e53935;
  color: white;
}
.MuiInput-underline:before {
  transition: none !important;
}
.MuiInput-underline:after {
  transition: none !important;
}
.MuiFormLabel-root {
  font-weight: 500 !important;
  font-size: 1.1rem !important;
}
.MuiList-root a {
  text-decoration: none;
  color: #004d40;
  font-size: 1rem;
}
.MuiAccordion-root,
.MuiAccordionSummary-root,
.MuiCollapse-root {
  transition: none !important;
}

.MuiAccordion-root::before,
.MuiAccordion-root::after {
  transition: none !important;
}
.MuiAccordionSummary-root {
  background: #52c234; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #0f3601,
    #52c234
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #0f3601,
    #52c234
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.MuiAccordionSummary-expandIcon .MuiIconButton-label {
  color: white;
}
.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
  background-color: red !important;
}
.settingInput {
  margin-bottom: 20px !important;
}
.settingBox {
  border-right: 1px solid rgb(158, 158, 158);
}

span.ant-layout-sider-zero-width-trigger.ant-layout-sider-zero-width-trigger-left {
  display: none;
}
.logo {
  text-align: center;
  padding-top: 20px;
  color: white;
}
.avatarText {
  color: #fafafa;
}
span.ant-avatar.ant-avatar-circle.ant-avatar-image {
  margin-bottom: 5px;
}
.avatarTextHide {
  display: none;
}

span.ant-typography.ant-typography-avatarText {
  color: #fafafa;
}

.header-link {
  color: #fafafa;
  font-weight: bold;
}
.signinCol {
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 10px;
  background-color: white;
}
.login-form-forgot {
  float: right;
}
.already-register-or-login {
  float: right;
}
.login-form-button {
  margin-top: 10px;
}
.settingPanel > .ant-collapse-header {
  color: #fafafa !important;
  font-weight: bold !important;
}
.save-setting-button {
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}
.save-setting-button:focus {
  box-shadow: none;
}
.ant-table-thead > tr > th {
  text-align: center !important;
}
.ghtk {
  color: #f1f1f1;
  background-color: #1890ff;
  border-radius: 20px;
  padding: 7px;
  font-size: 12px;
}
.ghn {
  color: #f1f1f1;
  background-color: #d46b08;
  border-radius: 20px;
  padding: 7px;
  font-size: 12px;
}
.ghtk-dadoisoat {
  color: #f1f1f1;
  background-color: #cf1322;
  border-radius: 20px;
  padding: 7px;
  font-size: 12px;
}
.grab {
  color: #f1f1f1;
  background-color: #389e0d;
  border-radius: 20px;
  padding: 7px;
}
.table-row-light {
  background-color: #ffffff;
  cursor: pointer;
}
.table-row-dark {
  background-color: #f2fffc;
  cursor: pointer;
}
.ant-select-clear {
  right: 25px !important;
}
.ant-modal-body {
  padding: 15px !important;
}
.ant-row-flex {
  display: flex;
  justify-content: space-around;
}
.ant-radio-flex {
  display: flex;
  width: 100%;
}
.chon-trang-thai-don-hang > ul > li:hover {
  background-color: #dff5cc !important;
}
.order-list-table-row {
  cursor: pointer;
}

.thong-ke-dropdown {
  background-color: #531dab;
  color: #f1f1f1;
  border: 1px solid rgb(255, 120, 117);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  font-weight: bold;
}
.thong-ke-dropdown:hover {
  background-color: #722ed1;
  color: #f1f1f1;
}
.productvitri {
  width: 150px;
  min-width: 150px;
}
.expanded-product-row {
  background-color: #d9f7be;
}
.product-delete-button {
  background-color: #cf1322;
  color: #fdfdfd;
  font-weight: bold;
  border: 1px solid #cf1322;
  box-shadow: #ff4d4f 0px 5px 15px;
}
.product-delete-button:hover {
  background-color: #f5222d !important;
  box-shadow: #ff4d4f 0px 5px 15px !important;
  color: #fdfdfd;
  border: 1px solid #f5222d;
  font-weight: bold;
}
.product-copy-button {
  background-color: #389e0d;
  color: #fdfdfd;
  font-weight: bold;
  border: 1px solid #389e0d;
  box-shadow: #95de64 0px 5px 15px;
}
.product-copy-button:hover {
  background-color: #52c41a !important;
  box-shadow: #95de64 0px 5px 15px !important;
  color: #fdfdfd;
  border: 1px solid #52c41a;
  font-weight: bold;
}

.product-update-button {
  background-color: #096dd9;
  color: #fdfdfd;
  font-weight: bold;
  border: 1px solid #096dd9;
  box-shadow: #69c0ff 0px 5px 15px;
}
.product-update-button:hover {
  background-color: #1890ff !important;
  box-shadow: #69c0ff 0px 5px 15px !important;
  color: #fdfdfd;
  border: 1px solid #1890ff;
  font-weight: bold;
}
.report-card {
  line-height: 32px;
  font-size: 24px;
  height: 32px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0;
}
input#shippingFee {
  text-align: right;
}
.order-product-detail-table {
  border: 1px solid #d9d9d9;
  margin-top: 20px;
  height: 203px;
}

.order-product-detail-table .ant-table-thead > tr > th {
  color: #fafafa;
  background-color: #8c8c8c;
  padding-top: 5px;
  padding-bottom: 5px;
}
tr.ant-table-row.ant-table-row-level-0.product-table-row > td {
  padding: 5px 8px 5px 8px;
}
.ant-modal-footer {
  text-align: center !important;
}
.don-hang-da-huy {
  background-color: #fff1f0 !important;
  color: #8c8c8c !important;
}
@media (min-width: 577px) {
  .already-register-or-login {
    margin-right: '20px';
  }
  .site-layout-header {
    padding-left: 20px;
    color: #fafafa;
    font-weight: bold;
  }
  .orders-head-row {
    padding: 10px;
  }
}

@media (min-width: 769px) {
  .user-setting-left-col {
    border-right: 1px solid #d9d9d9;
  }

  .site-layout-header {
    padding-left: 20px;
    color: #fafafa;
    font-weight: bold;
  }
  .orders-head-action-button {
    margin-left: 10px;
    background-color: rgb(0, 80, 179);
    color: #efefef;
    border: 1px solid rgb(255, 120, 117);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .orders-head-action-button:hover {
    background-color: #3f6600;
    color: #f1f1f1;
  }
  .orders-head-action-button:focus {
    background-color: #871400;
    color: #fafafa;
  }
  .product-head-action-button {
    margin-left: 10px;
    background-color: rgb(0, 80, 179);
    color: #efefef;
    border: 1px solid rgb(255, 120, 117);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 20px;
  }
  .product-head-action-button:hover {
    background-color: #3f6600;
    color: #f1f1f1;
  }
  .product-head-action-button:focus {
    background-color: #871400;
    color: #fafafa;
  }
}

@media (max-width: 576px) {
  .chon-trang-thai-don-hang {
    margin-bottom: 8px;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 10px 5px !important;
  }
  .ant-table {
    font-size: 12px;
  }
  .order-product-detail-table {
    border: 1px solid #d9d9d9;
    margin-top: 20px;
    height: 200px;
    margin-bottom: 10px;
    font-size: 12px;
  }
  .ant-modal-body {
    padding: 5px !important;
  }
  .order-collapse-panel {
    margin-bottom: 3px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px !important;
    background-image: linear-gradient(
      to top,
      #1e3c72 0%,
      #1e3c72 1%,
      #2a5298 100%
    );
  }
  .order-collapse-panel > .ant-collapse-header {
    color: #efefef !important;
    font-weight: bold;
  }
  .new-order-mobile-form {
    margin-bottom: 10px !important;
    border-bottom: 1px solid #d9d9d9;
  }
  .new-order-mobile-form:active {
    border-bottom: 1px solid #1890ff !important;
  }
  .new-order-mobile-form:focus {
    border-bottom: 1px solid #1890ff !important;
  }
  .ant-select-selection-search-input {
    border-bottom: 1px solid #d9d9d9;
  }
  .new-order-mobile-input:focus,
  .ant-select-selection-search-input:focus {
    border-bottom: 1px solid #1890ff !important;
  }
  .grab {
    color: #f1f1f1;
    background-color: #389e0d;
    border-radius: 25px;
    padding: 3px 5px 5px 5px;
  }
  .dashboard-card {
    margin: 0 10px;
    height: 70px;
  }
  .dashboard-card-icon {
    font-size: 40px;
  }
  .dashboard-cart-content {
    margin: 0px;
    margin-left: 10px;
    padding: 0;
  }
  .site-layout-header {
    padding-left: 5px;
    color: #fafafa;
    font-weight: bold;
    padding-right: 5px !important;
  }
  .report-card {
    line-height: 32px;
    font-size: 22px;
    height: 28px;
    margin-left: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0;
  }
  .productname-col {
    width: 80%;
  }
  .purchased-col {
    width: 32%;
  }
  .phone-col {
    width: 33%;
  }
  .header-link {
    font-size: 90%;
    width: 80px;
    /* text-align: center; */
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .orders-head-row {
    padding: 5px;
  }
  .mobile-orders-head-button {
    color: #efefef;
    margin-top: 10px;
    margin-bottom: 5px;
    margin-right: 5px;
  }
  .mobile-orders-head-button > .anticon + span,
  .mobile-orders-head-button > span + .anticon {
    margin-left: 4px;
  }
}
@media (max-width: 1199px) and (min-width: 1024px) {
  .so-don-hang-da-chon {
    display: none;
  }
  .ant-tabs-tab {
    font-size: 11.5px !important;
  }
  .ant-tabs-card.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
    padding: 7px 8px 6px !important;
  }
  .orders-search-col {
    left: 58.33%;
  }
  .orders-button-col {
    right: 41.66%;
  }
  .ma-san-pham {
    max-width: 11%;
    width: 11%;
    min-width: 11%;
  }
  .gia-von {
    max-width: 9%;
    width: 9%;
    min-width: 9%;
  }
  .ton-kho {
    max-width: 8%;
    width: 8%;
    min-width: 8%;
  }
  .vi-tri {
    max-width: 20%;
    width: 20%;
    min-width: 20%;
  }
}
@media (min-width: 1200px) {
  .orders-search-col {
    left: 75%;
  }
  .orders-button-col {
    right: 25%;
  }
  .dashboard-card {
    margin: 0 3px;
    height: 75px;
  }
  .dashboard-card-icon {
    font-size: 50px;
  }
  .dashboard-cart-content {
    margin: 0px;
    margin-left: 10px;
    font-size: 110%;
    padding: 0;
  }
  .site-layout-header {
    padding-left: 5px;
    color: #fafafa;
    font-weight: bold;
    padding-right: 5px !important;
  }
  .report-card {
    line-height: 32px;
    font-size: 24px;
    height: 30px;
    margin-left: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0;
  }
  .productname-col {
    width: 80%;
  }
  .purchased-col {
    width: 32%;
  }
  .phone-col {
    width: 33%;
  }
}
@media (min-width: 577px) and (max-width: 767px) {
  .dashboard-card {
    margin: 0 10px;
    height: 90px;
  }
  .dashboard-card-icon {
    font-size: 50px;
  }
  .dashboard-cart-content {
    margin: 5px;
    font-size: 110%;
  }
  .site-layout-header {
    padding-left: 5px;
    color: #fafafa;
    font-weight: bold;
    padding-right: 5px !important;
  }
  .report-card {
    line-height: 32px;
    font-size: 24px;
    height: 30px;
    margin-left: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0;
  }
  .productname-col {
    width: 80%;
  }
  .purchased-col {
    width: 32%;
  }
  .phone-col {
    width: 33%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .dashboard-card {
    margin: 0 3px;
    height: 80px;
  }
  .dashboard-card-icon {
    font-size: 50px;
  }
  .dashboard-cart-content {
    margin: 0px;
    margin-left: 10px;
    font-size: 110%;
    padding: 0;
  }
  .site-layout-header {
    padding-left: 5px;
    color: #fafafa;
    font-weight: bold;
    padding-right: 5px !important;
  }
  .report-card {
    line-height: 32px;
    font-size: 24px;
    height: 30px;
    margin-left: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0;
  }
  .productname-col {
    width: 80%;
  }
  .purchased-col {
    width: 32%;
  }
  .phone-col {
    width: 33%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .dashboard-card {
    margin: 0 3px;
    height: 75px;
  }
  .dashboard-card-icon {
    font-size: 40px;
  }
  .dashboard-cart-content {
    margin: 0px;
    margin-left: 10px;
    font-size: 100%;
    padding: 0;
  }
  .site-layout-header {
    padding-left: 5px;
    color: #fafafa;
    font-weight: bold;
    padding-right: 5px !important;
  }
  .report-card {
    line-height: 32px;
    font-size: 24px;
    height: 30px;
    margin-left: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0;
  }
  .productname-col {
    width: 80%;
  }
  .purchased-col {
    width: 32%;
  }
  .phone-col {
    width: 33%;
  }
}
